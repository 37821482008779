import React, { useEffect, useState } from 'react'
import logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    // alert(isMenuOpen)
  };
  useEffect(() => {
    if (isMenuOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
  }, [isMenuOpen]);

  return (
    <>
      <div className='header '>
        <div className="container primaryBoxShadow">
          <div className="row justify-content-between align-items-center py-2 ">
            <div className="col-lg-2 col-md-2 col-6">
              <figure className=''>
                <a href="">
                  <img src={logo} alt="" width={220} className='img-fluid' />
                </a>
              </figure>
            </div>

            <div className="col-lg-8 col-md-8 navigationBar">
              <ul className='navbarNav'>
                <li className='navItem'>
                  <a className='navLink' href="">Home</a>
                </li>
                <li className='navItem'>
                  <a className='navLink' href="">Services</a>
                </li>
                <li className='navItem'>
                  <a className='navLink' href="">About Us</a>
                </li>
                <li className='navItem'>
                  <a className='navLink' href="">Achivements</a>
                </li>
                <li className='navItem'>
                  <a className='navLink' href="">Career</a>
                </li>
              </ul>
            </div>
            <div className="col-lg-2 col-md-2 col-4">
              <button className='btn_primery primaryBoxShadow'>Contact Us</button>

            </div>
            <div className="col-2 d-md-none ">
              <button className='btn_primery px-3' onClick={toggleMenu}><FontAwesomeIcon icon={faBars} /></button>
            </div>
          </div>
        </div>
      </div>

      <div className={`overlay ${isMenuOpen ? 'open' : ''}`} onClick={toggleMenu}></div>
      <div className={`mobileNavigationBar ${isMenuOpen ? 'open' : ''}`}>
        <div className="mHeader d-flex justify-content-between align-items-center  p-3">
          <div className="mlogo ">
            <figure className=''>
              <a href="">
                <img src={logo} alt="" width={220} className='img-fluid' />
              </a>
            </figure>
          </div>
          <button className='btn_primery px-2 ' onClick={toggleMenu}><FontAwesomeIcon icon={faTimes} /></button>
        </div>
        <ul className='navbarNav'>
          <li className='navItem'>
            <a className='navLink' href="">Home</a>
          </li>
          <li className='navItem'>
            <a className='navLink' href="">Services</a>
          </li>
          <li className='navItem'>
            <a className='navLink' href="">About Us</a>
          </li>
          <li className='navItem'>
            <a className='navLink' href="">Achivements</a>
          </li>
          <li className='navItem'>
            <a className='navLink' href="">Career</a>
          </li>
        </ul>
      </div>
    </>
  )
}
