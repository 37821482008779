import React, { useEffect, useState } from 'react'
import Header from '../components/Header'

import bannerImage from '../images/banner_person_img.png'
import team from '../images/fluent_people-team-16-filled.png'
import growth from '../images/fluent_arrow-growth-20-filled.png'
import google from '../images/logos_google.png'
import servicePerson from '../images/service_person.png'
import growthService from '../images/carbon_growth.png'
import marketing from '../images/nimbus_marketing.png'
import webdev from '../images/solar_laptop-bold-duotone.png'
import helpAndSupport from '../images/streamline_customer-support-1-solid.png'
import bussiness from '../images/bxs_business.png'
import hospital from '../images/Hospital.png'
import html from '../images/devicon_html5.png'
import client1 from '../images/client1.png'
import playButton from '../images/playButton.png'
import quote from '../images/quote.png'
import resarch from '../images/resarch.png'
import rightArrow from '../images/rightArrow.png'
import Shopify from '../images/logos_shopify.png'
import Microsoft from '../images/logos_microsoft.png'
import Tableau from '../images/logos_tableau-icon.png'
import Power_BI from '../images/logos_microsoft-power-bi.png'
import Kevan from '../images/KEVAN.png'
import AireMed from '../images/AireMed.png'
import Midwestern from '../images/Midwestern.png'
import BTCS_Group from '../images/BTCS_Group.png'
import Anchor from '../images/Anchor.png'
import Olivo_Group from '../images/Olivo_Group.png'
import Granada from '../images/Granada.png'
import Farewellmedia from '../images/Farewellmedia.png'
import Spice_Digital from '../images/Spice Digital.png'
import Twenty20 from '../images/Twenty20.png'
import Jinx from '../images/jinx.png'
import Shad_Agency from '../images/Shad_Agency.png'
import Vero_health from '../images/Vero_health.png'
import PrintOffice from '../images/PrintOffice.png'
import SJR from '../images/SJR.png'
import Analysis from '../images/icon-park_market-analysis.png'
import Development from '../images/carbon_cloud-service-management.png'
import SEO from '../images/icon-park_seo.png'
import Advertising from '../images/fxemoji_speaker.png'
import Hosting from '../images/streamline_network-solid.png'
import call from '../images/ic_baseline-call.png'
import Email from '../images/mdi_email.png'
import Address from '../images/mdi_location.png'
import redPeople from '../images/fluent_people-team-16-red.png'
import happyClient from '../images/foundation_torso-business.png'
import Projects from '../images/material-symbols_fact-check.png'
import Stories from '../images/material-symbols_reviews.png'
import hours_Worked from '../images/mdi_account-network.png'
import Education from '../images/Education.png'
import Ecommerc from '../images/Ecommerc.png'
import Fashions from '../images/Fashions.png'
import Hospital from '../images/Hospital.png'
import industry from '../images/industry.png'
import Law from '../images/Law.png'
import Accounting from '../images/Accounting.png'
import Restaurent from '../images/Restaurent.png'
import Coursite from '../images/Coursite.png'
import Education1 from '../images/Education1.png'
import Learnings from '../images/Learnings.png'
import Medicals from '../images/Medicals.png'
import StarSchool from '../images/StarSchool.png'
import Educations2 from '../images/Educations2.png'
import Css from '../images/devicon_css3.png'
import Bootstrap from '../images/devicon_bootstrap.png'
import Js from '../images/openmoji_javascript.png'
import ReactLogo from '../images/logos_react.png'
import Php from '../images/logos_php.png'
import Python from '../images/logos_python.png'
import MySql from '../images/logos_mysql.png'
import Kotlin from '../images/logos_kotlin.png'
import Swift from '../images/devicon_swift-wordmark.png'
import client2 from '../images/client2.png'
import client3 from '../images/client3.png'
import client4 from '../images/client4.png'
import client5 from '../images/client5.png'
import blog2 from '../images/blog2.png'
import blog3 from '../images/blog3.png'
import blog4 from '../images/blog4.png'
import blog5 from '../images/blog5.png'
import blog6 from '../images/blog6.png'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendar, faEye } from '@fortawesome/free-solid-svg-icons'
import Footer from '../components/Footer'



export default function Home() {
  window.onload = function() {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    name: '',
    inquiryType: '',
    email: '',
    phone: '',
    message: '',
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.name) errors.name = 'Name is required';
    if (!formData.inquiryType) errors.inquiryType = 'Inquiry Type is required';

    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!formData.email) {
      errors.email = 'Email is required';
    } else if (!emailPattern.test(formData.email)) {
      errors.email = 'Invalid email format';
    }

    const phonePattern = /^\d{10}$/;
    if (!formData.phone) {
      errors.phone = 'Phone number is required';
    } else if (!phonePattern.test(formData.phone)) {
      errors.phone = 'Phone number must be 10 digits';
    }

    if (!formData.message) errors.message = 'Message is required';
    return errors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    const errors = validateForm();
    setFormErrors(errors);
    setIsSubmitted(true);

    if (Object.keys(errors).length === 0) {
      // Form is valid, submit it
      console.log('Form submitted successfully', formData);
      // Reset form data
      setFormData({
        name: '',
        inquiryType: '',
        email: '',
        phone: '',
        message: '',
      });
      setIsSubmitted(false);
    }
  };
  return (
    <>
      <div className='bannerSection'>
        <Header />
        <div className="container">
          <div className="row justify-content-between align-items-center ">
            <div className="col-lg-6">
              <div className="digitalDream">
                <div className="bannerHeading">
                  <h1>Where Digital Dreams Become Reality - Your Journey to Success <br /> <span className='redText'> Starts Here! </span>
                  </h1>
                </div>

                <div className="bannerText">
                  <p className='primaryText pe-1'>At Cast, we harness the latest technologies to deliver cutting-edge
                    digital solutions tailored to meet your business needs. From immersive
                    web experiences powered by AI and machine learning to data-driven
                    insights and advanced SEO strategies, we're here to help you succeed
                    in today's digital landscape.</p>
                </div>

                <div className='cta mt_20'>
                  <button className='btn_primery bookCall'>Book A Call</button>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="bannerImage">
                <figure className='text-center '>
                  <img src={bannerImage} alt="" className='img-fluid ' />
                </figure>
                <div className="bannerCard1" data-aos="fade-right" data-aos-duration="1000" >
                  <figure>
                    <img src={team} alt="" />
                  </figure>
                  <h4 className='fs_20 fs_14'>Creative Team</h4>
                </div>
                <div className="bannerCard1 bannerCard2" data-aos="fade-left" data-aos-duration="1000"  >
                  <figure>
                    <img src={growth} alt="" />
                  </figure>
                  <h4 className='fs_20 fs_14'>Fast Growth</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="partners">
            <div className="partnerHead mb_54">
              <h2 className='fs_38 fw_600 text-center'>Our Trusted <span className='redText'>Partners</span> </h2>
              <p className='primaryText text-center  mx-auto' >Lorem ipsum dolor sit amet consectetur. Aliquet adipiscing eu sagittis risus. Sapien nunc venenatis quis ipsum
                mattis magna. Sem tortor tempor in etiam ultricies eu ipsum eros sem. </p>
            </div>

            <div className="row row-cols-lg-5  gy-5">
              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={google} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Google</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Shopify} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Shopify</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Microsoft} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Microsoft</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Tableau} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Tableau</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Power_BI} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Power BI</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Kevan} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Kevan</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={AireMed} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>AireMed</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Midwestern} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Midwestern</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={BTCS_Group} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>BTCS Group</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Anchor} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Anchor</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Olivo_Group} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Olivo Group</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Granada} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Granada</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Farewellmedia} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Farewellmedia</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Spice_Digital} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Spice Digital</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Twenty20} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Twenty20</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Jinx} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Jinx!</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Shad_Agency} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Shad Agency</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={Vero_health} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>Vero health</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={PrintOffice} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>PrintOffice.com</h5>
                </div>
              </div>

              <div className="col-6  col-md-4 col">
                <div className="partnerCard">
                  <figure>
                    <img src={SJR} alt="" className="img-fluid" />
                  </figure>
                  <h5 className='fw_500 fs_19 fs_15'>SJR Research</h5>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>

      <div className="services">
        <div className="container">
          <div className="row align-items-center ">
            <div className="col-lg-5">
              <div className="serviceHeading">
                <h2 className='whiteText fw_700 fs_58 lh_90 fs_38 lh_48'>Discover Our
                  Comprehensive
                  Range of Services
                </h2>
              </div>
              <div className='cta mt_20'>
                <button className='btn_Secondary'>Book A Call</button>
              </div>
            </div>

            <div className="col-lg-7">
              <div className="serviceImages">
                <figure className='text-lg-end text-md-center '>
                  <img src={servicePerson} alt="" className='img-fluid ' />
                  <div className="serviceMiniCard" data-aos="fade-right" data-aos-duration="1000" >
                    <div className="growth">
                      <figure>
                        <img src={growthService} alt="" />
                      </figure>
                      <h4 className='fs_20 fw_500 pe_40'>100% Growth</h4>
                    </div>
                    <div className="cover">
                      <figure>
                        <img src={marketing} alt="" />
                      </figure>
                      <h4 className='fs_20 fw_500 pe_40'>70% Cover</h4>
                    </div>
                  </div>
                </figure>


              </div>
            </div>
          </div>

          <div className='serviceCardWraper'>
            <div className="row gy-4">
              <div className="col-lg-4 col-md-6 col-sm-6 ">
                <div className="serviceCard">
                  <figure>
                    <img src={webdev} alt="" />
                  </figure>
                  <div className="serviceCardContent">
                    <h3 className='fs_18 fw_600'>Next-Gen Web Development & Design</h3>
                    <p className='fs_12 fw_400 gray_text'>Elevate your online presence with our next-gen web development
                      and design services. Utilizing the latest technologies such as
                      Progressive Web Apps (PWAs), headless CMS, and responsive design
                      frameworks, we create stunning, lightning-fast websites that engage
                      and convert visitors.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 ">
                <div className="serviceCard">
                  <figure>
                    <img src={Analysis} alt="" />
                  </figure>
                  <div className="serviceCardContent">
                    <h3 className='fs_18 fw_600'>Advanced Data Analysis & Visualization</h3>
                    <p className='fs_12 fw_400 gray_text'>Unlock the full potential of your data with our advanced data
                      analysis and visualization solutions. Leveraging AI and predictive
                      analytics, we turn raw data into actionable insights and interactive
                      visualizations that drive informed decisionmaking and business
                      growth.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 ">
                <div className="serviceCard">
                  <figure>
                    <img src={Development} alt="" />
                  </figure>
                  <div className="serviceCardContent">
                    <h3 className='fs_18 fw_600'>Bespoke Custom Website Development</h3>
                    <p className='fs_12 fw_400 gray_text'>Stand out from the competition with our bespoke custom website
                      development services. Using cutting-edge development tools and
                      frameworks like React.js, Vue.js, and GraphQL, we build tailored
                      solutions that meet your unique requirements and exceed your
                      expectations.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 ">
                <div className="serviceCard">
                  <figure>
                    <img src={SEO} alt="" />
                  </figure>
                  <div className="serviceCardContent">
                    <h3 className='fs_18 fw_600'>AI-Powered SEO Optimization</h3>
                    <p className='fs_12 fw_400 gray_text'>Dominate the search engine rankings with our AI-powered SEO
                      optimization strategies. Our advanced SEO techniques leverage
                      natural language processing (NLP) and machine learning
                      algorithms to identify trends, optimize content, and drive targeted
                      traffic to your website.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 ">
                <div className="serviceCard">
                  <figure>
                    <img src={Advertising} alt="" />
                  </figure>
                  <div className="serviceCardContent">
                    <h3 className='fs_18 fw_600'>Smart Google Advertising Campaigns</h3>
                    <p className='fs_12 fw_400 gray_text'>Maximize your ROI with our smart Google advertising campaigns.
                      Using machine learning and automated bidding strategies, we
                      target the right audience at the right time, ensuring your ads reach
                      their full potential and drive results.</p>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6 col-sm-6 ">
                <div className="serviceCard">
                  <figure>
                    <img src={Hosting} alt="" />
                  </figure>
                  <div className="serviceCardContent">
                    <h3 className='fs_18 fw_600'>Web Hosting</h3>
                    <p className='fs_12 fw_400 gray_text'>Web hosting services offer the necessary infrastructure for
                      websites to be accessible online, providing storage, bandwidth,
                      and technical support for reliable performance and security.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contact">
        <div className="container">
          <div className="contactHead">
            <h2 className='fs_38 fw_600 text-center fs_30'>
              <span className='redText'>Contact </span>
              With Us</h2>
            <p class="primaryText text-center mx-auto ">Ready to take your business to the next level? Get in touch with us today to discuss your project requirements and
              discover how we can help you achieve your goals with the latest in digital technology.</p>
          </div>

          <div className="row pt_60">
            <div className="col-lg-5" data-aos="fade-right" data-aos-duration="1000" >
              <div className="contactCard mb_60 mb_30">
                <figure>
                  <img src={helpAndSupport} alt="" />
                </figure>
                <div className="contactCardText ">
                  <h4 className='fs_20 fw_500 fs_18'>Help & Support</h4>
                  <p className='fw_500 gray_text fs_16 fs_14'>Lorem ipsum dolor sit amet consectetur.
                    Aliquet adipiscing eu sagittis risus. Sapien
                    nunc venenatis quis ipsum.</p>
                </div>
              </div>

              <div className="contactCard mb_60 mb_30">
                <figure>
                  <img src={call} alt="" />
                </figure>
                <div className="contactCardText ">
                  <h4 className='fs_20 fw_500 fs_18'>Contact Us</h4>
                  <p className='fw_500 gray_text fs_16 fs_14'>Lorem ipsum dolor sit amet consectetur.
                    Aliquet adipiscing eu sagittis risus. Sapien
                    nunc venenatis quis ipsum.</p>
                </div>
              </div>

              <div className="contactCard mb_60 mb_30">
                <figure>
                  <img src={Email} alt="" />
                </figure>
                <div className="contactCardText ">
                  <h4 className='fs_20 fw_500 fs_18'>Send Email</h4>
                  <p className='fw_500 gray_text fs_16 fs_14'>Lorem ipsum dolor sit amet consectetur.
                    Aliquet adipiscing eu sagittis risus. Sapien
                    nunc venenatis quis ipsum.</p>
                </div>
              </div>

              <div className="contactCard mb_60 mb_30">
                <figure>
                  <img src={Address} alt="" />
                </figure>
                <div className="contactCardText ">
                  <h4 className='fs_20 fw_500 fs_18'>Our Address</h4>
                  <p className='fw_500 gray_text fs_16 fs_14'>Lorem ipsum dolor sit amet consectetur.
                    Aliquet adipiscing eu sagittis risus. Sapien
                    nunc venenatis quis ipsum.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="contactForm" data-aos="fade-left" data-aos-duration="1000"> 
                <form onSubmit={handleSubmit}>
                  <div className="inputOuter mb_40 mb_16">
                    <label className='d-block fs_22 fw_400 mb_10 fs_18'>Name</label>
                    <div className="formInput">
                      <input
                        className='w-100'
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleInputChange}
                      />
                      {isSubmitted && formErrors.name && (
                        <span className='error' style={{ color: 'red' }}>{formErrors.name}</span>
                      )}
                    </div>
                  </div>

                  <div className="inputOuter mb_40 mb_16">
                    <label className='d-block fs_22 fw_400 mb_10 fs_18'>Inquiry Type</label>
                    <div className="formInput">
                      <input
                        className='w-100'
                        type="text"
                        name="inquiryType"
                        value={formData.inquiryType}
                        onChange={handleInputChange}
                      />
                      {isSubmitted && formErrors.inquiryType && (
                        <span className='error' style={{ color: 'red' }}>{formErrors.inquiryType}</span>
                      )}
                    </div>
                  </div>

                  <div className="inputOuter mb_40 mb_16">
                    <label className='d-block fs_22 fw_400 mb_10 fs_18'>Email</label>
                    <div className="formInput">
                      <input
                        className='w-100'
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleInputChange}
                      />
                      {isSubmitted && formErrors.email && (
                        <span className='error' style={{ color: 'red' }}>{formErrors.email}</span>
                      )}
                    </div>
                  </div>

                  <div className="inputOuter mb_40 mb_16">
                    <label className='d-block fs_22 fw_400 mb_10 fs_18'>Phone</label>
                    <div className="formInput">
                      <input
                        className='w-100'
                        type="text"
                        name="phone"
                        value={formData.phone}
                        onChange={handleInputChange}
                      />
                      {isSubmitted && formErrors.phone && (
                        <span className='error' style={{ color: 'red' }}>{formErrors.phone}</span>
                      )}
                    </div>
                  </div>

                  <div className="inputOuter mb_40 mb_16">
                    <label className='d-block fs_22 fw_400 mb_10 fd_18'>Message</label>
                    <div className="formInput">
                      <textarea
                        rows={5}
                        name="message"
                        className='w-100'
                        value={formData.message}
                        onChange={handleInputChange}
                      ></textarea>
                      {isSubmitted && formErrors.message && (
                        <span className='error' style={{ color: 'red' }}>{formErrors.message}</span>
                      )}
                    </div>
                  </div>

                  <div className='cta mt_20 text-center '>
                    <button className='btn_primery primaryBoxShadow' style={{ padding: "12.5px 47px" }}>Book A Call</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="achivement">
        <div className="row border_bottom pb_71 mb_51 pb_40 me-md-0">
          <div className="col-md-6">
            <div className="achiveCard">
              <figure>
                <img src={bussiness} alt="Business" />
              </figure>
              <div className="achiveTextContent d-flex align-items-center ">
                <h3 className='whiteText fs_78 fw_600 fs_45'>15</h3>
                <h4 className='whiteText fs_38 lh_44 fs_20 normal_lh'>Years In <br /> Business</h4>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="achiveCard border-0 justify-content-center justify-content-lg-end ">
              <figure>
                <img src={redPeople} alt="Team Members" />
              </figure>
              <div className="achiveTextContent d-flex align-items-center ">
                <h3 className='whiteText fs_78 fw_600 fs_45'>15</h3>
                <h4 className='whiteText fs_38 lh_44 fs_20 normal_lh'>Team<br /> Members</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="row me-md-0 ">
          <div className="col-6 col-md-3 achivementCardBorder">
            <div className="achiveCardRow2 align-items-lg-start">
              <figure>
                <img src={happyClient} alt="Happy Clients" />
              </figure>
              <div className="achiveTextContent d-flex flex-column">
                <h3 className='whiteText fs_58 fw_600 fs_40'>15</h3>
                <h4 className='whiteText fs_28 lh_44 fs_20 normal_lh'>Happy Clients <br /></h4>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3 achivementCardBorder">
            <div className="achiveCardRow2 align-items-center padding_60">
              <figure>
                <img src={Projects} alt="Successful Projects" />
              </figure>
              <div className="achiveTextContent d-flex flex-column">
                <h3 className='whiteText fs_58 fw_600 fs_40'>15</h3>
                <h4 className='whiteText fs_28 lh_44 fs_20 normal_lh'>Successful Projects</h4>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3 achivementCardBorder">
            <div className="achiveCardRow2 align-items-center padding_60">
              <figure>
                <img src={Stories} alt="Accepted User Stories" />
              </figure>
              <div className="achiveTextContent d-flex flex-column">
                <h3 className='whiteText fs_58 fw_600 fs_40'>15</h3>
                <h4 className='whiteText fs_28 lh_44 fs_20 normal_lh'>Accepted User Stories</h4>
              </div>
            </div>
          </div>

          <div className="col-6 col-md-3 achivementCardBorder border-0">
            <div className="achiveCardRow2 align-items-end padding_60Left">
              <figure>
                <img src={hours_Worked} alt="Hours Worked" />
              </figure>
              <div className="achiveTextContent d-flex flex-column">
                <h3 className='whiteText fs_58 fw_600 fs_40'>15</h3>
                <h4 className='whiteText fs_28 lh_44 fs_20'>Hours Worked</h4>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="industry">
        <div className="container">
          <div className="industryHead mb_54">
            <h2 className='fs_38 fw_600 text-center fs_30'>Industries We <span className='redText'>Worked</span> </h2>
            <p className='primaryText text-center w-75 mx-auto'>
              Lorem ipsum dolor sit amet consectetur. Aliquet adipiscing eu sagittis risus. Sapien nunc venenatis quis ipsum mattis magna. Sem tortor tempor in etiam ultricies eu ipsum eros sem.
            </p>
          </div>

          <div className="row gy-3 workedOuter">
            <div className="col-sm-4 col-md-4 col-lg-3 px-2 workedWraper">
              <div className="workedIndustry">
                <figure>
                  <a href="">
                    <img src={hospital} alt="" className='img-fluid' />
                  </a>
                </figure>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 px-2 workedWraper">
              <div className="workedIndustry">
                <figure>
                  <a href="">
                    <img src={Accounting} alt="" className='img-fluid' />
                  </a>
                </figure>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 px-2 workedWraper">
              <div className="workedIndustry">
                <figure>
                  <a href="">
                    <img src={Education} alt="" className='img-fluid' />
                  </a>
                </figure>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 px-2 workedWraper">
              <div className="workedIndustry">
                <figure>
                  <a href="">
                    <img src={Fashions} alt="" className='img-fluid' />
                  </a>
                </figure>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 px-2 workedWraper">
              <div className="workedIndustry">
                <figure>
                  <a href="">
                    <img src={industry} alt="" className='img-fluid' />
                  </a>
                </figure>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 px-2 workedWraper">
              <div className="workedIndustry">
                <figure>
                  <a href="">
                    <img src={Restaurent} alt="" className='img-fluid' />
                  </a>
                </figure>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 px-2 workedWraper">
              <div className="workedIndustry">
                <figure>
                  <a href="">
                    <img src={Law} alt="" className='img-fluid' />
                  </a>
                </figure>
              </div>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-3 px-2 workedWraper">
              <div className="workedIndustry">
                <figure>
                  <a href="">
                    <img src={Ecommerc} alt="" className='img-fluid' />
                  </a>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="portfolio">
        <div className="container">
          <div className="portfolioHead mb_54">
            <h2 className='fs_38 fw_600 text-center whiteText fs_30'>Our <span className='redText'>Portfolio</span> </h2>
            <p className='primaryText text-center mx-auto whiteText'>Explore our portfolio to see how we've helped businesses like yours succeed with our
              innovative digital solutions. From AIdriven websites to data-driven marketing
              campaigns, we've delivered results that make a difference. </p>
          </div>
          <div className="portfolioCardsWraper mt_130">
            <div className="row gy-3 portfolioCardOuter ">
              <div className="col-lg-4 col-md-4 px-2 projectOuter">
                <div className="portfolioWraper" data-aos="fade-right" data-aos-duration="1000" >
                  <figure>
                    <a href="">
                      <img src={Coursite} alt="" />
                    </a>
                  </figure>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 px-2 transletTop projectOuter transletTopZero">
                <div className="portfolioWraper" data-aos="fade-up" data-aos-duration="1000">
                   <figure>
                    <a href="">
                      <img src={Learnings} alt="" />
                    </a>
                  </figure>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 px-2 projectOuter">
                <div className="portfolioWraper" data-aos="fade-up-left" data-aos-duration="1000">
                  <figure>
                    <a href="">
                      <img src={StarSchool} alt="" />
                    </a>
                  </figure>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 px-2 projectOuter">
                <div className="portfolioWraper" data-aos="fade-up-right" data-aos-duration="1000">
                  <figure>
                    <a href="">
                      <img src={Education1} alt="" />
                    </a>
                  </figure>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 px-2 transletTop projectOuter transletTopZero">
                <div className="portfolioWraper" data-aos="fade-up" data-aos-duration="1000">
                   <figure>
                    <a href="">
                      <img src={Medicals} alt="" />
                    </a>
                  </figure>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 px-2 projectOuter">
                <div className="portfolioWraper" data-aos="fade-up-left" data-aos-duration="1000">
                  <figure>
                    <a href="">
                      <img src={Educations2} alt="" />
                    </a>
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="technology">
        <div className="container">
          <div className="technologyHead mb_54">
            <h2 className='fs_38 fw_600 text-center fs_30'>Technology We  <span className='redText'>Use</span> </h2>
            <p className='primaryText text-center mx-auto'>Lorem ipsum dolor sit amet consectetur. Aliquet adipiscing eu sagittis risus. Sapien nunc venenatis quis ipsum
              mattis magna. Sem tortor tempor in etiam ultricies eu ipsum eros sem. </p>
          </div>

          <div className="row row-cols-lg-5 gy-3 technoCardRow">
            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={html} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>

            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={Css} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>

            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={Bootstrap} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>

            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={Js} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>

            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={ReactLogo} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>

            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={Php} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>

            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={Python} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>

            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={MySql} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>

            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={Kotlin} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>

            <div className="col col-md-4 col-sm-3 technoCardWraper">
              <div className="technoCard">
                <a href="">
                  <img src={Swift} alt="" className='img-fluid ' />
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="testimonials">
        <div className="container">
          <div className="testimonialsHead mb_54 ">
            <h2 className='fs_38 fw_600 text-center whiteText fs_30'>What Client Says About Us</h2>
            <p className='primaryText text-center mx-auto whiteText'>Lorem ipsum dolor sit amet consectetur. Aliquet adipiscing eu sagittis risus. Sapien nunc venenatis quis ipsum
              mattis magna. Sem tortor tempor in etiam ultricies eu ipsum eros sem.  </p>
          </div>

          <div className="row align-items-center mb_51 text-md-center ">
            <div className="col-lg-6">
              <div className="clientProfile" data-aos="fade-right" data-aos-duration="1000" >
                <figure>
                  <img src={client1} alt="" className='img-fluid ' />
                  <div className='playButton'>
                    <a href="">
                      <img src={playButton} alt="" />
                    </a>
                  </div>
                </figure>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="clientText" data-aos="fade-left" data-aos-duration="1000"> 
                <div className="quoteImg mb_20">
                  <img src={quote} alt="" />
                </div>
                <p className='fs_22 lh_35 fw_300 mb_30 whiteText fs_17'>Lorem ipsum dolor sit amet consectetur. Arcu viverra nunc cras
                  elementum et sit dictum. Egestas lorem leo ac netus nulla. Congue
                  volutpat aliquam phasellus magna tempus. Auctor eget diam volutpat
                  suscipit dictum ut pulvinar et proin. Ullamcorper interdum aenean lacus
                  nullam at integer.</p>
                <h4 className='clientName fs_20 fw_600 whiteText'>Client Name</h4>
                <h5 className='fs_16 fw_400 whiteText'>Profession</h5>
              </div>
            </div>
          </div>

          <div className="row clientOuter">
            <div className="col-lg-3 col-md-4 mb-md-4 clientWraper">
              <div className="clientProfile" data-aos="fade-up" data-aos-duration="1000">
                 <figure>
                  <img src={client2} alt="" className='img-fluid' />
                  <div className='playButton'>
                    <a href="">
                      <img src={playButton} alt="" width={56} height={60} />
                    </a>
                  </div>
                </figure>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 mb-md-4 clientWraper">
              <div className="clientProfile" data-aos="fade-up" data-aos-duration="1000">
                 <figure>
                  <img src={client3} alt="" className='img-fluid' />
                  <div className='playButton'>
                    <a href="">
                      <img src={playButton} alt="" width={56} height={60} />
                    </a>
                  </div>
                </figure>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 mb-md-4 clientWraper">
              <div className="clientProfile" data-aos="fade-up" data-aos-duration="1000">
                 <figure>
                  <img src={client4} alt="" className='img-fluid' />
                  <div className='playButton'>
                    <a href="">
                      <img src={playButton} alt="" width={56} height={60} />
                    </a>
                  </div>
                </figure>
              </div>
            </div>

            <div className="col-lg-3 col-md-4 mb-md-4 clientWraper">
              <div className="clientProfile" data-aos="fade-up" data-aos-duration="1000">
                 <figure>
                  <img src={client5} alt="" className='img-fluid' />
                  <div className='playButton'>
                    <a href="">
                      <img src={playButton} alt="" width={56} height={60} />
                    </a>
                  </div>
                </figure>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="news">
        <div className="container">
          <div className="newsHead mb_54">
            <h2 className='fs_38 fw_600 text-center fs_30'>Our Latest   <span className='redText'>News</span> </h2>
            <p className='primaryText text-center mx-auto'>Stay ahead of the curve with insights from our blog, where we share the latest trends, technologies, and best
              practices in web development, data analysis, SEO, and digital marketing. Whether you're a business owner,
              marketer, or developer, you'll find valuable information to fuel your success.</p>
          </div>

          <div className="row blogOuter">
            <div className="col-lg-4 col-md-4 blogWraper">
              <div className="newsCard">
                <figure >
                  <img src={resarch} alt="" className='img-fluid ' />
                </figure>
                <div className="newsText">
                  <h3 className='fs_20 fw_600 mb_10 fs_17'>Blog Title</h3>
                  <p className='fs_12'>Lorem ipsum dolor sit amet consectetur. Eu lectus pulvinar
                    lorem ac. Eu tempus nibh sollicitudin dui proin tristique
                    ipsum nibh quam. Quisque felis tincidunt orci turpis proin
                    amet. </p>

                  <div className="timeAndView d-flex ">
                    <div className="time">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faCalendar} /> 25 FEB 2024</h5>
                    </div>
                    <div className="view">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faEye} />40</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 blogWraper">
              <div className="newsCard">
                <figure>
                  <img src={blog2} alt="" className='img-fluid ' />
                </figure>
                <div className="newsText">
                  <h3 className='fs_20 fw_600 mb_10 fs_17'>Blog Title</h3>
                  <p className='fs_12'>Lorem ipsum dolor sit amet consectetur. Eu lectus pulvinar
                    lorem ac. Eu tempus nibh sollicitudin dui proin tristique
                    ipsum nibh quam. Quisque felis tincidunt orci turpis proin
                    amet. </p>

                  <div className="timeAndView d-flex ">
                    <div className="time">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faCalendar} /> 25 FEB 2024</h5>
                    </div>
                    <div className="view">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faEye} />40</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 blogWraper">
              <div className="newsCard">
                <figure>
                  <img src={blog3} alt="" className='img-fluid ' />
                </figure>
                <div className="newsText">
                  <h3 className='fs_20 fw_600 mb_10 fs_17'>Blog Title</h3>
                  <p className='fs_12'>Lorem ipsum dolor sit amet consectetur. Eu lectus pulvinar
                    lorem ac. Eu tempus nibh sollicitudin dui proin tristique
                    ipsum nibh quam. Quisque felis tincidunt orci turpis proin
                    amet. </p>

                  <div className="timeAndView d-flex ">
                    <div className="time">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faCalendar} /> 25 FEB 2024</h5>
                    </div>
                    <div className="view">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faEye} />40</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 blogWraper">
              <div className="newsCard">
                <figure>
                  <img src={blog4} alt="" className='img-fluid ' />
                </figure>
                <div className="newsText">
                  <h3 className='fs_20 fw_600 mb_10 fs_17'>Blog Title</h3>
                  <p className='fs_12'>Lorem ipsum dolor sit amet consectetur. Eu lectus pulvinar
                    lorem ac. Eu tempus nibh sollicitudin dui proin tristique
                    ipsum nibh quam. Quisque felis tincidunt orci turpis proin
                    amet. </p>

                  <div className="timeAndView d-flex ">
                    <div className="time">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faCalendar} /> 25 FEB 2024</h5>
                    </div>
                    <div className="view">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faEye} />40</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 blogWraper">
              <div className="newsCard">
                <figure>
                  <img src={blog5} alt="" className='img-fluid ' />
                </figure>
                <div className="newsText">
                  <h3 className='fs_20 fw_600 mb_10 fs_17'>Blog Title</h3>
                  <p className='fs_12'>Lorem ipsum dolor sit amet consectetur. Eu lectus pulvinar
                    lorem ac. Eu tempus nibh sollicitudin dui proin tristique
                    ipsum nibh quam. Quisque felis tincidunt orci turpis proin
                    amet. </p>

                  <div className="timeAndView d-flex ">
                    <div className="time">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faCalendar} /> 25 FEB 2024</h5>
                    </div>
                    <div className="view">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faEye} />40</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 blogWraper">
              <div className="newsCard">
                <figure>
                  <img src={blog6} alt="" className='img-fluid ' />
                </figure>
                <div className="newsText">
                  <h3 className='fs_20 fw_600 mb_10 fs_17'>Blog Title</h3>
                  <p className='fs_12'>Lorem ipsum dolor sit amet consectetur. Eu lectus pulvinar
                    lorem ac. Eu tempus nibh sollicitudin dui proin tristique
                    ipsum nibh quam. Quisque felis tincidunt orci turpis proin
                    amet. </p>

                  <div className="timeAndView d-flex ">
                    <div className="time">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faCalendar} /> 25 FEB 2024</h5>
                    </div>
                    <div className="view">
                      <h5 className='fs_12 fw_400'><FontAwesomeIcon icon={faEye} />40</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="subscribe">
        <div className="container">
          <div className="row align-items-center justify-content-between ">
            <div className="col-lg-6">
              <h3 className='fs_38 fw_500 subscribeHeading fs_30'>Subscribe to watch latest <span className='redText'>news</span> </h3>
            </div>
            <div className="col-lg-2 d-sm-none arrowNone">
              <figure>
                <img src={rightArrow} alt="" className='img-fluid ' />
              </figure>
            </div>
            <div className="col-lg-4">


              <div className="subscribe_Cta">
                <div className="subscribeForm d-flex justify-content-between align-items-center ">
                  <div className="subscribeInput ps-3 ">
                    <input type="text" className='p-2 border-0' placeholder='Please Enter Mail id' />
                  </div>
                  <div className='cta'>
                    <button className='btn_primery primaryBoxShadow'>Button</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  )
}
