import { faFacebook, faGit, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faGithub } from '@fortawesome/free-brands-svg-icons/faGithub'
import { faQuestion } from '@fortawesome/free-solid-svg-icons'
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons/faQuestionCircle'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

export default function Footer() {
  return (
    <>
      <footer>
        <div className="container">
          <div className="footerWraper">
            <div className="row">
              <div className="col-lg-3">
                <div className="footerAbout ">
                  <p className='fs_18 whiteText mb-4 '>Lorem ipsum dolor sit amet consectetur.
                    Faucibus eu lorem nisl sed a varius risus
                    egestas nisl. Felis erat tincidunt vitae.</p>
                  <div className="socialIcon">
                    <a href="">
                      <span><FontAwesomeIcon icon={faLinkedin} className='whiteText fs_42' /></span>
                    </a>
                    <a href="">
                      <span><FontAwesomeIcon icon={faInstagram} className='whiteText fs_42' /></span>
                    </a>
                    <a href="">
                      <span><FontAwesomeIcon icon={faGithub} className='whiteText fs_42' /></span>
                    </a>
                    <a href="">
                      <span><FontAwesomeIcon icon={faFacebook} className='whiteText fs_42' /></span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footerMenu">
                  <h4 className='whiteText fs_24 fw_600 mb_30'>Services</h4>
                  <ul>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>Data Science</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>Digital marketing</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>E-commerce</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>Graphic design</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>Ebook design</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footerMenu">
                  <h4 className='whiteText fs_24 fw_600 mb_30'>Resources</h4>
                  <ul>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>About us</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>Feature</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>Achievements</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>Career</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-3">
                <div className="footerMenu">
                  <h4 className='whiteText fs_24 fw_600 mb_30'>Support</h4>
                  <ul>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>+91 12345 12345</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>email@gmail.com</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>Canada: City: Montreal
                        State/Province: Quebec
                        Postal code: H3H2M6</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>Australia: Melbourne</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>USA: Mason ohio</a>
                    </li>
                    <li className='mb_25'>
                      <a href="" className='whiteText'>India: Nagaur, Rajasthan
                        Phursungi Haveli Pune
                        Maharashtra</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>

          <div className="copyrightContent">
            <div className="row">
              <div className="col-lg-6">
                <div className="termPolicy">
                  <a href='' className='fs_22px fw_600 whiteText'>Privacy Policy</a>
                  <a href='' className='fs_22px fw_600 whiteText'>Terms of service</a>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="copyrightText d-flex align-items-center ">
                  <p className='whiteText'>© 2024 Copyright CAST. All rights reserved.</p>

                  <div className="helpBtn whiteText"><button><FontAwesomeIcon icon={faQuestionCircle} className='me-2 ' />Help</button></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}
